import React from "react";
import BannerImg from "../assets/img/plant.jpg";

export default function Home() {
  return (
    <main>
      <section className="welcome-section container">
        <img className="welcome-pic" src={BannerImg} alt="camera-img" />
        <h3 className="welcome-title">Hi Shutterbugs!</h3>
        <p className="welcome-subtitle">WELCOME TO OCTO's HOME</p>
      </section>
      <hr />
      <section className="about-section container">
        <h3 className="about-title">ABOUT OCTO</h3>
        <p className="about-descp">
          OCTO is an Indian Stereoscopic Company. pvt Ltd. who brings the
          revolutionary idea of; 3D Stero Photography, Videography, CG & Art.
          OCTO provides you his well designed 3d Stereoscope & free Stereo
          Camera App for your mobile. With this App you can now able to capture
          3d photos on your mobile phone and enjoy them with Octo Stereoscope
          anytime, anywhere :)
        </p>
      </section>
      <hr />
      <section className="stereoscopy-section container">
        <h3 className="stereo-title">STEREOSCOPY</h3>
        <p className="setreo-descp">
          Is a technique & art of 3D photography & videograph; It works with two
          slightly different angles of flat 2D images, which creates a very
          gorgeous DEPTH information.It was invented by Sir Charles Wheatson in
          1838.
        </p>
        <p className="setreo-descp">
          Steroscopy is also called stereography,sterecopics,stereo
          photogrammetry, stereo imaging or 3D photography. & these 3D photos
          are also called Stereograms Or Stereophotos.
        </p>
        <p className="setreo-descp">
          Stereoscopy is uses for films,games,art,space exploration, clinical,
          mathematical,scientific,engineering, & much more uses for
          entertainment and educational purposes.
        </p>
      </section>
    </main>
  );
}
