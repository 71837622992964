import React from "react";
import PlantImg from "../assets/img/plant.jpg";
import BirdImg from "../assets/img/bird.jpg";
import YoutubeImage from "../assets/img/youtube.png";
import InstaImage from "../assets/img/insta.png";

export default function Gallery() {
  return (
    <main className="container">
      <section>
        <h3 className="gallery-title">STEREO PHOTOS</h3>
        <img src={PlantImg} alt="plant-img" className="gallery-img" />
        <div>
          <img
            src={InstaImage}
            alt="insta-img"
            className="social-img"
          />
        </div>
        <a href="#" className="gallery-btn">
          CHANNEL
        </a>
      </section>
      <hr />
      <section>
        <h3 className="gallery-title">STEREO VIDEOS</h3>
        <img
          src={BirdImg}
          alt="stereoscope-img"
          className="gallery-img"
        />
        <div>
          <img
            src={YoutubeImage}
            alt="youtube-img"
            className="social-img"
          />
        </div>
        <a href="#" className="gallery-btn">
          CHANNEL
        </a>
      </section>
    </main>
  );
}
