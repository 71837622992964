import React from "react";
import ScopeImg from "../assets/img/scope.png";
import OctoScopeImg from "../assets/img/octoscop.png";
// import OctoScopeImg from "../assets/img/octoscop.png";
import { Link } from "react-router-dom";

export default function Product() {
  return (
    <main className="container">
      <section>
        <h3 className="prodcut-title">STEREOSCOPE</h3>
        <img
          src={ScopeImg}
          alt="stereoscope-img"
          className="products-img"
        />
        <Link to="/checkout" className="products-btn">
          BUY
        </Link>
      </section>
      <hr />
      <section>
        <h3 className="prodcut-title">STEREO CAMERA APP</h3>
        <img
          src={OctoScopeImg}
          alt="stereoscope-img"
          className="products-img"
        />
        <a href="#" className="products-btn">
          DOWNLOAD
        </a>
      </section>
      <hr />
      <section>
        <h3 className="prodcut-title">STEREO PHOTOBOOK</h3>
        <img
          src={OctoScopeImg}
          alt="stereoscope-img"
          className="products-img"
        />
        <p className="product-descp">SEND US YOUR FAVOURITE STEREO PHOTOS</p>
        <p className="product-subdescp">
          we will make mini 3D photobook for you :)
        </p>
        <a href="#" className="products-btn">
          WHATSAPP
        </a>
      </section>
    </main>
  );
}
