import React from "react";
import FounderImg from "../assets/img/user.png";

export default function Contact() {
  return (
    <main>
      <div>
        <img src={FounderImg} alt="founder" width="100" />
        <h3 className="contact contact-user">Diljit Singh</h3>
        <h4 className="contact founder">FOUNDER</h4>
      </div>
      <hr />
      <div>
        <h3 className="contact">OFFICE</h3>
        <p className="contact address">
          Blockno.10, Chandigarh Road, JamalPur| Ludhiana | Punjab.
        </p>
      </div>
      <hr />
      <div>
        <h3 className="contact">CHAT | CALL</h3>
        <p className="contact">+918264411397</p>
        <p className="contact">+918264411397</p>
      </div>
      <hr />
      <div>
        <h3 className="contact">MAIL</h3>
        <p className="contact">octostudios.in@gmail.com</p>
      </div>
    </main>
  );
}
