import React from 'react'

export default function Footer() {
  return (
    <footer>
      <p className="caption">
        Copyright &#169; 2023, <a href="/" className="caption-link">OCTOSTUDIOS</a>
      </p>
    </footer>
  )
}
