import React from "react";
import { NavLink  } from "react-router-dom";
import Logo from "../assets/img/logo.png";

export default function Header() {
  return (
    <header className="header">
      <div>
        <img className="header-logo" src={Logo} alt="logo" />
        <h4 className="logo-subtitle">The Indian Stereoscopic Company.</h4>
      </div>
      <nav className="navbar container">
        <li className="nav-list">
          <NavLink  to="/" className="nav-link">
            HOME
          </NavLink>
        </li>
        <li className="nav-list">
          <NavLink to="/product" className="nav-link">
            PRODUCTS
          </NavLink>
        </li>
        <li className="nav-list">
          <NavLink to="/gallery" className="nav-link">
            GALLERY
          </NavLink>
        </li>
        <li className="nav-list">
          <NavLink to="/contact" className="nav-link">
            CONTACT
          </NavLink>
        </li>
      </nav>
    </header>
  );
}
