import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/home";
import Header from "./components/header";
import Footer from "./components/footer";
import Product from "./views/product";
import Gallery from "./views/gallery";
import Contact from "./views/contact";
import Checkout from "./views/checkout";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
