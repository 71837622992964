import React, { useState } from "react";
import ScopeImg from "../assets/img/scope.png";
import GooglePayButton from "@google-pay/button-react";

const fields = {
  f_name: "",
  l_name: "",
  address: "",
  city: "",
  country: "India",
  postal_code: "",
  phone: "",
  qty: 1,
};

export default function Checkout() {
  const [field, setField] = useState(fields);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setField({
      ...field,
      [name]: value,
    });
  };
  return (
    <main className="container">
      <div className="checkout-container">
        <h1>Checkout</h1>
        <div className="form-container">
          <div className="form-group">
            <div className="form-elemrnt">
              <input
                type="text"
                placeholder="First Name"
                name="f_name"
                value={field.f_name}
                onChange={handleChange}
              />
            </div>
            <div className="form-elemrnt">
              <input
                type="text"
                placeholder="Last Name"
                name="l_name"
                value={field.l_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-elemrnt">
            <input
              type="text"
              placeholder="Address"
              name="address"
              value={field.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <div className="form-elemrnt">
              <input
                type="text"
                placeholder="City"
                name="city"
                value={field.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-elemrnt">
              <input
                type="text"
                placeholder="Country"
                name="country"
                value={field.country}
                onChange={handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-elemrnt">
              <input
                type="text"
                placeholder="Postal Code"
                name="postal_code"
                value={field.postal_code}
                onChange={handleChange}
              />
            </div>
            <div className="form-elemrnt">
              <input
                type="number"
                placeholder="Phone Number"
                name="phone"
                value={field.phone}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="product-cont">
          <div className="product-img">
            <img src={ScopeImg} alt="" />
          </div>
          <div className="product-content">
            <h4>STEREOSCOPE</h4>
            <div className="form-elemrnt">
              <input
                type="number"
                placeholder="1"
                min={1}
                name="qty"
                value={field.qty}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <GooglePayButton
          environment="TEST"
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: "CARD",
                parameters: {
                  allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                  allowedCardNetworks: ["MASTERCARD", "Rupay"],
                },
                tokenizationSpecification: {
                  type: "PAYMENT_GATEWAY",
                  parameters: {
                    gateway: "example",
                    gatewayMerchantId: "exampleGatewayMerchantId",
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: "BCR2DN4T52F6VRBW",
              merchantName: "Octostudio",
            },
            transactionInfo: {
              totalPriceStatus: "FINAL",
              totalPriceLabel: "Total",
              totalPrice: "100.00",
              currencyCode: "INR",
              countryCode: "IN",
            },
          }}
          onLoadPaymentData={(paymentRequest) => {
            console.log("load payment data", paymentRequest);
          }}
        />
      </div>
    </main>
  );
}
